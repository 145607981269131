<template>
  <div class="pageWrap">
    <button-box :buttonBoxState.sync="buttonBoxState" @Callback="functionCall"></button-box>
    <ldl-table-pagination :selectRow.sync="selectRow" :style="{height:'calc(100% - '+ bottomHeight + (buttonBoxState?' - 35px':' - 15px')+')'}" @getList="getList" :tableDataInfo="tableDataInfo" :pagingData.sync="pagingData"></ldl-table-pagination>
  </div>
</template>

<script>
  import ldlTablePagination from "@/components/ldlTablePagination";
  import buttonBox from "@/components/buttonBox";
  import {getList} from "@/api/Account/AccountFunds";
  export default {
    name: "AccountFunds",
    components:{
      ldlTablePagination,
      buttonBox,
    },
    data(){
      return{
        expressList: [],
        express: '',
        tableData: [],
        tableHeader: [],
        uploadLoading: false,
        uploadShow: false,
        /**必要参数*/
        selectRow:undefined, //选中行
        pagingData:undefined,//getList的传参
        bottomHeight: '0%',//底部高度
        buttonBoxState:true,//开启按钮行的状态
        tableDataInfo:{ //表格信息
          dataListInfo:[
            {prop:'companyName',label:'单位名称'},
            {prop:'z_capital',label:'单位资金',sortable:"custom"},
            {prop:'credit_capital',label:'信誉锁定金额',sortable:"custom"},
            {prop:'tkz_capital_lock',label:'提款锁定',sortable:"custom"},
            {prop:'ke_capital',label:'可提资金',sortable:"custom"},
            {prop:'th_capital_lock',label:'退换货锁定金额',sortable:"custom"},
            {prop:'tkz_zc_capital',label:'-提款划出金额',sortable:"custom"},
            {prop:'tkz_zr_capital',label:'+提款转入金额',sortable:"custom"},
            {prop:'th_capital',label:'-退换货金额',sortable:"custom"},
            {prop:'produc_zr_capital',label:'+产品交易的收入',sortable:"custom"},
            {prop:'xl_produc_zr_capital',label:'+虚拟产品交易的收入',sortable:"custom"},
            {prop:'purchase_capital_lock',label:'代理进货锁定金额',sortable:"custom"},
            {prop:'other_in',label:'+其他转入',sortable:"custom"},
            {prop:'other_out',label:'-其他转出',sortable:"custom"},
            {prop:'pd_zr_capital',label:'-盘点的责任金额',sortable:"custom"},
          ],//表格列信息
          dataList:[]//表格行信息
        },
        /**必要参数*/
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.getList()
      })
    },
    methods:{

      //导出
      getExport() {
        exports({key:this.pagingData})
          .then(res => {
            const a = document.createElement('a')
            a.setAttribute('href', `${res.data}`)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a) // 下载后移除a标签
          })
          .catch(err => this.$message.error(err))
      },

      /**这是按钮方法调用*/
      functionCall(name) {
        if (name.length == 1) {
          this[name[0]]();
        } else {
          this[name[0]](name[1]);
        }
      },
      /**这是按钮方法调用*/

      getList(){
        let pagingData={... this.pagingData}
        getList(pagingData).then(res=>{
          this.pagingData={...
              this.pagingData,
            page:res.data.current_page,
            limit:res.data.per_page,
            total:res.data.total,
          }
          let tableDataInfo={... this.tableDataInfo,dataList:res.data.data}
          this.tableDataInfo=tableDataInfo
        })
      }
    },

  }
</script>

<style scoped>
  .expressSelect {
    margin-bottom: 20px;
  }
</style>
<style>
  .FR_modalFoot{
    display: block !important;
    width: 100% !important;
    padding-top: 10px !important;
    text-align: center;
  }
</style>