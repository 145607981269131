<template>
  <div class="pageContainer">
    <div class="searchCondition clearfix">
      <div class="searchConditionBox fl" style="margin-right:10px;">
        <span>单位名称：</span>
        <div class="disb">
          <el-select
            v-model="companyName"
            size="mini"
            clearable
            filterable
            :filter-method="getCompany"
            @visible-change="getCompany"
            placeholder="请输入内容"
          >
            <el-option
              v-for="item in company_id_arr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchConditionBox fl">
        <span>时间：</span>
        <div class="disb">
          <el-date-picker
            v-model="time"
            size="mini"
            type="datetimerange"
            value-format="yyyy-mm-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="searchConditionBox fl">
        <div class="disb">
          <el-button size="mini" type="primary" @click="search">查询</el-button>
        </div>
      </div>
    </div>
    <el-table
      v-loading="tableLoading"
      :data="TableData"
      border
      size="mini"
      :height="sysHeigth*10"
      :highlight-current-row="true"
      @row-click="ListClick"
    >
      <el-table-column label="单位名称" prop="company_name" align="center" />
      <el-table-column label="期初余额" prop="before_money" align="center" sortable="custom" />
      <el-table-column label="收入" prop="income_fee" align="center" sortable="custom" />
      <el-table-column label="收入笔数" prop="income_number" align="center" sortable="custom" />
      <el-table-column label="支出" prop="expend_fee" align="center" sortable="custom" />
      <el-table-column label="支出笔数" prop="expend_number" align="center" sortable="custom" />
      <el-table-column label="日终金额" prop="after_money" align="center" sortable="custom" />
      <el-table-column label="日期" prop="time" align="center">
        <template slot-scope="{row}">
          <span>{{ row.time | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">业务明细导出</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get_list, exports, getCompanyList } from "@/api/Account/FundBill";
export default {
  name: "FundBill",
  data() {
    return {
      sysHeigth: (document.documentElement.clientHeight - 130) / 10,
      TableData: [],
      tableLoading: false,
      ListSelected: {},
      companyName: "",
      company_id_arr: [],
      time: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
      this.getCompany();
    });
  },
  methods: {
    ListClick(row) {
      this.ListSelected = row;
    },
    checkList() {
      let data = this.ListSelected;
      return new Promise((resolve) => {
        if (data) {
          resolve(data);
        } else {
          this.$message({
            message: "您需要选中一条列表已进行需要的操作!",
            type: "warning",
          });
        }
      });
    },

    getCompany(e) {
      let data = {
        keys: e ? e : "",
      };
      getCompanyList(data).then((res) => {
        this.company_id_arr = res.data;
      });
    },

    //业务明细导出
    handleClick(row) {
      let data = {
        company_id: row.company_id,
        time: row.time,
      };
      exports(data)
        .then((res) => {
          if (res.code == 200) {
            const a = document.createElement("a");
            a.setAttribute("href", `${res.data}`);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch();
    },

    search() {
      if (this.companyName == "" && this.time.length <= 0) {
        this.$message.error("请先输入查询信息");
      } else {
        let obj = {
          company_id: this.companyName,
          start_time: this.time[0],
          end_time: this.time[1],
        };
        this.getList(obj);
      }
    },

    /**这是按钮方法调用*/
    functionCall(name) {
      // console.log(name)
      if (name.length == 1) {
        this[name[0]]();
      } else {
        this[name[0]](name[1]);
      }
    },
    /**这是按钮方法调用*/

    getList(e) {
      this.tableLoading = true;
      let data = { ...e };
      get_list(data)
        .then((res) => {
          this.TableData = res.data;
          this.tableLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  *zoom: 1;
}
.fr {
  float: right;
}

.fl {
  float: left;
}
.disb {
  display: inline-block;
}
.pageContainer {
  padding: 10px 8px 0;
}
.searchCondition {
  .searchConditionBox {
    width: 280px;
    display: inline-block;
    padding-bottom: 10px;
  }
}
</style>