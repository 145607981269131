import request from '@/utils/request'
// 交易管理/订单管理/列表
export function getList(data) {
    return request({
        url: '/admin/order/order_list',
        method: 'post',
        data: data
    })
}

// 底部信息
// 参数名	必选	类型	说明
// id	是	int	订单id
// type	是	string	类型 1商品信息，2操作信息
// name	否	string	昵称
export function goodsInfo_footer(data) {
    return request({
        url: '/admin/order/son_order',
        method: 'post',
        data: data
    })
}


