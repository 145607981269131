<template>
  <div class="pageWrap">
    <button-box :buttonBoxState.sync="buttonBoxState" @Callback="functionCall"></button-box>
    <ldl-table-pagination
      :selectRow.sync="selectRow"
      @listClick="listClick"
      :style="{height:'calc(100% - '+ bottomHeight + (buttonBoxState?' - 35px':' - 15px')+')'}"
      @getList="getList"
      :tableDataInfo="tableDataInfo"
      :pagingData.sync="pagingData"
    ></ldl-table-pagination>
    <ldlControlWindow class="ldlTab" :bottomHeight.sync="bottomHeight" ref="bottomHeight">
      <el-tabs
        type="border-card"
        v-model="tabPaneValue"
        style="height: calc(100% - 4px)"
        @tab-click="changeTab"
      >
        <el-tab-pane
          label="商品信息"
          name="goodsInfo1"
          :disabled="!selectRow"
          style="height: calc(100% - 4px)"
        >
          <el-table
            :data="bottomList[tabPaneValue]"
            border
            size="mini"
            height="100%"
            style="width: 100%;"
          >
            <el-table-column prop="product_id" align="center" label="商品名称"></el-table-column>
            <el-table-column prop="product_sku_name" align="center" label="商品规格">
              <template slot-scope="scope">
                <div v-for="(item,index) in scope.row.product_sku_name" :key="index">
                  <span type="success">{{ item }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="price" align="center" label="商品价格"></el-table-column>
            <el-table-column prop="number" align="center" label="商品数量"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          label="操作信息"
          name="goodsInfo2"
          :disabled="!selectRow"
          style="height:calc(100% - 4px)"
        >
          <el-table
            :data="bottomList[tabPaneValue]"
            border
            size="mini"
            height="100%"
            style="width: 100%;"
          >
            <el-table-column prop="user_id" align="center" label="操作员工"></el-table-column>
            <el-table-column prop="operation_type" align="center" label="操作类型"></el-table-column>
            <el-table-column prop="operation_remarks" align="center" label="备注信息"></el-table-column>
            <el-table-column prop="create_time" align="center" label="操作时间"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </ldlControlWindow>
  </div>
</template>

<script>
import ldlTablePagination from "@/components/ldlTablePagination";
import buttonBox from "@/components/buttonBox";
import ldlControlWindow from "@/components/ldlControlWindow";
import { getList, goodsInfo_footer } from "@/api/TransactionManage/OrderManage";
export default {
  name: "OrderManage",
  components: {
    ldlTablePagination,
    buttonBox,
    ldlControlWindow,
  },
  data() {
    return {
      bottomList: {},
      tabPaneValue: "goodsInfo1",
      expressList: [],
      express: "",
      tableData: [],
      tableHeader: [],
      uploadLoading: false,
      uploadShow: false,
      /**必要参数*/
      selectRow: undefined, //选中行
      pagingData: undefined, //getList的传参
      bottomHeight: "30%", //底部高度
      buttonBoxState: false, //开启按钮行的状态
      tableDataInfo: {
        //表格信息
        dataListInfo: [
          { prop: "order_code", label: "订单编号", width: 160 },
          { prop: "create_time", label: "下单时间", width: 160 },
          { prop: "status_ex", label: "订单状态" },
          {
            prop: "create_time",
            label: "下单时间",
            sortable: "custom",
            width: 160,
          },
          { prop: "order_mold", label: "订单类型" },
          { prop: "order_type", label: "交易平台" },
          { prop: "company_id", label: "成交单位" },
          { prop: "user_id", label: "成交员工" },
          { prop: "ke_company_id", label: "客服单位" },
          { prop: "ke_user_id", label: "客服员工" },
          { prop: "ke_zf_fee", label: "客户支付金额" },
          { prop: "discount_fee", label: "优惠金额" },
          { prop: "ke_zf_freight", label: "客户支付运费" },
          { prop: "charge_fee", label: "手续费" },
          { prop: "is_vip", label: "vip" },
          { prop: "pay_time", label: "付款时间", sortable: "custom" },
          { prop: "pays_status", label: "付款状态" },
          { prop: "pays_fail_type", label: "支付失败原因" },
          { prop: "ship_time", label: "发货时间", sortable: "custom" },
          { prop: "sign_time", label: "签收时间", sortable: "custom" },
          { prop: "fahuo_fieight", label: "发货运费" },
          { prop: "order_remarks", label: "订单备注" },
          { prop: "product_fee", label: "商品金额", sortable: "custom" },
          { prop: "settle_time", label: "结算时间", sortable: "custom" },
        ], //表格列信息
        dataList: [], //表格行信息
      },
      /**必要参数*/
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    //导出对账差异
    getExport() {
      exports({ key: this.pagingData, type: 1 })
        .then((res) => {
          const a = document.createElement("a");
          a.setAttribute("href", `${res.data}`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); // 下载后移除a标签
        })
        .catch((err) => this.$message.error(err));
    },

    listClick() {
      let tabPaneValue = this.tabPaneValue;
      let index = tabPaneValue.charAt(9);
      let data = {
        id: this.selectRow.id,
        type: index,
      };
      goodsInfo_footer(data).then((res) => {
        console.log(res);
        let bottomList = { ...this.bottomList };
        bottomList[this.tabPaneValue] = [...res.data];
        this.bottomList = { ...bottomList };
      });
    },

    //切换tab现在无用
    changeTab() {
      let tabPaneValue = this.tabPaneValue;
      let index = tabPaneValue.charAt(9);
      let data = {
        id: this.selectRow.id,
        type: index,
      };
      goodsInfo_footer(data).then((res) => {
        console.log(res);
        let bottomList = { ...this.bottomList };
        bottomList[this.tabPaneValue] = [...res.data];
        this.bottomList = { ...bottomList };
      });
    },

    /**这是按钮方法调用*/
    functionCall(name) {
      // console.log(name)
      if (name.length == 1) {
        this[name[0]]();
      } else {
        this[name[0]](name[1]);
      }
    },
    /**这是按钮方法调用*/

    getList() {
      let pagingData = { ...this.pagingData, type: 1 };
      getList(pagingData).then((res) => {
        this.pagingData = {
          ...this.pagingData,
          page: res.data.current_page,
          limit: res.data.per_page,
          total: res.data.total,
        };
        let tableDataInfo = { ...this.tableDataInfo, dataList: res.data.data };
        this.tableDataInfo = tableDataInfo;
      });
    },
  },
};
</script>

<style scoped>
.expressSelect {
  margin-bottom: 20px;
}
</style>
<style>
.FR_modalFoot {
  display: block !important;
  width: 100% !important;
  padding-top: 10px !important;
  text-align: center;
}
</style>