<template>
  <div class="pageWrap">
    <button-box :buttonBoxState.sync="buttonBoxState" @Callback="functionCall"></button-box>
    <ldl-table-pagination
      :selectRow.sync="selectRow"
      :style="{height:'calc(100% - '+ bottomHeight + (buttonBoxState?' - 35px':' - 15px')+')'}"
      @getList="getList"
      :tableDataInfo="tableDataInfo"
      :pagingData.sync="pagingData"
    ></ldl-table-pagination>
    <!-- 添加/修改弹框 -->
    <el-dialog
      :title="state=='add'?'新增退款途径':'修改退款途径'"
      :visible.sync="addTF"
      width="520px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        size="mini"
        v-loading="saveLoad"
        element-loading-text="正在保存"
        class="modalContainer"
        label-position="left"
      >
        <el-form-item
          prop="pay_path"
          label="收款途径名称"
          :rules="[{ required: true, message: '收款途径名称不能为空'}]"
        >
          <el-input v-model="form.pay_path"></el-input>
        </el-form-item>
        <el-form-item
          prop="company_id"
          label="所属公司"
          :rules="[{ required: true, message: '所属公司不能为空'}]"
        >
          <el-select
            v-model="form.company_id"
            filterable
            clearable
            placeholder="请输入所属公司名称"
            :filter-method="getCompany"
            @visible-change="getCompany"
          >
            <el-option
              v-for="item in company_id_arr"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="pay_type"
          label="收款类型"
          :rules="[{ required: true, message: '收款类型不能为空'}]"
        >
          <el-radio-group v-model="form.pay_type">
            <el-radio :label="3">银行转账</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="1">微信</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收款二维码">
          <el-upload
            ref="qrCodeUpload"
            list-type="picture-card"
            :limit="1"
            action="http://smx.tscoltd.com/apicommon/Upload/upload"
            accept="image/*"
            name="image"
            :file-list="qrCodeList"
            :on-success="qrCodeSuccess"
            :on-exceed="limitUpimg"
            :on-preview="qrCodeImgPrv"
            :on-remove="qrCodeImgRemove"
          >
            <i class="el-icon-plus" />
          </el-upload>
          <el-dialog :visible.sync="qrCodeImgvis" size="tiny" append-to-body>
            <img width="100%" :src="form.qr_code" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">否</el-radio>
            <el-radio :label="2">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="form.remake" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTF = false" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="changeFromSave(form)">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 添加/修改弹框 -->
  </div>
</template>

<script>
import ldlTablePagination from "@/components/ldlTablePagination";
import buttonBox from "@/components/buttonBox";
import {
  getList,
  add,
  edit,
  getCompanyList,
  changeState
} from "@/api/FundManage/CollectionChannels";
export default {
  name: "CollectionChannels",
  components: {
    ldlTablePagination,
    buttonBox,
  },
  data() {
    return {
      expressList: [],
      express: "",
      tableData: [],
      tableHeader: [],
      uploadLoading: false,
      uploadShow: false,
      /**必要参数*/
      selectRow: undefined, //选中行
      pagingData: undefined, //getList的传参
      bottomHeight: "0%", //底部高度
      buttonBoxState: true, //开启按钮行的状态
      tableDataInfo: {
        //表格信息
        dataListInfo: [
          { prop: "companyName", label: "所属公司名称" },
          { prop: "pay_path", label: "收款途径名称" },
          {
            prop: "pay_type",
            label: "收款类型",
            type: "tag",
            data: [
              { key: 3, name: "银行转账", type: "info" },
              { key: 2, name: "支付宝", type: "" },
              { key: 1, name: "微信", type: "success" },
            ],
          },
          {
            prop: "qr_code",
            label: "收款二维码",
            type: "image",
            fit: "",
            imgStyle: { width: "100px", height: "100px" },
          },
          {
            prop: "status",
            label: "是否启用",
            type: "tag",
            data: [{ key: 2, name: "启用", type: "success" }],
          },
          { prop: "remake", label: "备注信息" },
          {
            prop: "create_time",
            label: "创建时间",
            type: "date",
            sortable: "custom",
          },
        ], //表格列信息
        dataList: [], //表格行信息
      },
      /**必要参数*/

      //新增/编辑
      state: "add",
      addTF: false,
      form: {},
      saveLoad: false,
      qrCodeList: [],
      pay_path_arr: [],
      company_id_arr: [],
      qrCodeImgvis: false,
      //新增/编辑
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    /**这是按钮方法调用*/
    functionCall(name) {
      // console.log(name)
      if (name.length == 1) {
        this[name[0]]();
      } else {
        this[name[0]](name[1]);
      }
    },
    /**这是按钮方法调用*/

    getList() {
      let pagingData = { ...this.pagingData, type: 1 };
      getList(pagingData).then((res) => {
        this.pagingData = {
          ...this.pagingData,
          page: res.data.current_page,
          limit: res.data.per_page,
          total: res.data.total,
        };
        let tableDataInfo = { ...this.tableDataInfo, dataList: res.data.data };
        this.tableDataInfo = tableDataInfo;
      });
    },

    //获取所属公司名称
    getCompany(e) {
      let data = {
        companyName: e,
      };
      getCompanyList(data).then((res) => {
        this.company_id_arr = res.data;
      });
    },

    //设置启用
    setState() {
      if (this.selectRow) {
        this.$confirm(
          `该收款途径当前状态为${
            this.selectRow.status == 1 ? "禁用" : "启用"
          }, 是否修改为${this.selectRow.status == 1 ? "启用" : "禁用"}?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            let data = {
              id: this.selectRow.id,
              status: this.selectRow.status == 1 ? 2 : 1,
            };
            changeState(data).then(() => {
              this.highlightTF = false;
              this.selectRow = undefined;
              this.$message.success("设置成功");
              this.getList();
            });
          })
          .catch(() => {});
      } else {
        this.$message.error("请选择当前要操作的一行");
      }
    },

    // 新增/编辑
    visibleform(state) {
      if (state == "add") {
        this.state = "add";
        this.addTF = true;
        this.form = {
          status: 2,
          pay_type: 3,
        };
        this.$nextTick(function () {
          //移除绑定的表单项的校验结果，传入待移除的表单项的 prop 属性或者 prop 组成的数组，如不传则移除整个表单的校验结果
          this.$refs.form.clearValidate();
          this.$refs.qrCodeUpload.clearFiles(); //清空文件
        });
        this.getCompany();
      } else if (state == "edit") {
        if (this.selectRow) {
          this.form = { ...this.selectRow };
          this.getCompany(this.form.pay_path);
          this.qrCodeList = [{ name: "", url: this.form.qr_code }];
          this.state = "edit";
          this.addTF = true;
        } else {
          this.$message.error("请选择当前要操作的一行");
        }
      }
    },

    // 新增/编辑---->保存
    changeFromSave(data) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.state == "add") {
            this.saveload = true;
            add(data)
              .then(() => {
                this.$message.success("新增成功");
                this.addTF = false;
                this.saveLoad = false;
                this.getList();
              })
              .catch((err) => {
                this.$message({
                  message: err,
                  type: "error",
                });
              });
          } else if (this.state == "edit") {
            this.saveload = true;
            edit(data)
              .then(() => {
                this.$message.success("修改成功");
                this.addTF = false;
                this.highlightTF = false;
                this.selectRow = undefined;
                this.saveLoad = false;
                this.getList();
              })
              .catch((err) => {
                this.$message({
                  message: err,
                  type: "error",
                });
              });
          }
        }
      });
    },
    // 新增/编辑---->保存

    qrCodeSuccess(res) {
      // 上传成功回调
      this.form = { ...this.form, qr_code: res.data.src };
    },
    qrCodeImgPrv() {
      // 上传身份正面照预览
      this.qrCodeImgvis = true;
    },
    qrCodeImgRemove() {
      // 上传身份正面照移除
      this.form = { ...this.form, qr_code: null };
    },
    limitUpimg() {
      this.$message.error("收款二维码只能上传一张！");
    },
  },
};
</script>

<style scoped>
.expressSelect {
  margin-bottom: 20px;
}
</style>
<style>
.FR_modalFoot {
  display: block !important;
  width: 100% !important;
  padding-top: 10px !important;
  text-align: center;
}
</style>