import request from '@/utils/request'
/** 商品属性列表
 * dbname	否	string	排序字段 默认：create_time
 * sort	否	string	排序顺序 默认：desc
 * limit	否	int	条数 默认：20
 * page	否	int	页码 默认：1
 * key	否	json	高级搜索 key
 * type	是	int	传1
 */
export function getList(data) {
    return request({
        url: '/admin/water/getList',
        method: 'post',
        data: data
    })
}
/** 导出订单资金流水
 * key	否	json	高级搜索 key
 * type	是	int	传 1
 */
export function exports(data) {
    return request({
        url: '/admin/water/export',
        method: 'post',
        data: data
    })
}

