<template>
  <div class="pageWrap">
    <button-box :buttonBoxState.sync="buttonBoxState" @Callback="functionCall"></button-box>
    <ldl-table-pagination
      @listClick="listClick"
      :selectRow.sync="selectRow"
      :style="{height:'calc(100% - '+ bottomHeight + (buttonBoxState?' - 35px':' - 15px')+')'}"
      @getList="getList"
      :tableDataInfo="tableDataInfo"
      :pagingData.sync="pagingData"
    ></ldl-table-pagination>
    <ldlControlWindow class="ldlTab" :bottomHeight.sync="bottomHeight" ref="bottomHeight">
      <el-tabs type="border-card" style="height: calc(100% - 4px)">
        <el-tab-pane label="提款单操作日志" :disabled="!selectRow" style="height: calc(100% - 4px)">
          <el-table :data="applyGoodsArr" border size="mini" height="100%" style="width: 100%;">
            <el-table-column prop="id" align="center" label="编号"></el-table-column>
            <el-table-column prop="withdrawal_id" align="center" label="提款编号"></el-table-column>
            <el-table-column prop="account" align="center" label="操作人帐号"></el-table-column>
            <el-table-column prop="admin_user_id" align="center" label="操作人编号"></el-table-column>
            <el-table-column prop="create_time" width="200" align="center" label="操作时间"></el-table-column>
            <el-table-column prop="content" width="300" align="center" label="修改内容"></el-table-column>
            <el-table-column prop="type" align="center" label="操作类型"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </ldlControlWindow>

    <!-- <el-dialog
        v-el-drag-dialog
        width="30%"
        custom-class="minWidth500"
        :visible.sync="addAttributeState"
        :destroy-on-close="false"
        :close-on-click-modal="false"
        title="录入"
        size="mini"
        center
    >
      <el-form ref="form" :model="form" label-width="170px" size="mini" >
        <el-form-item label="审核状态:" prop="apply_status" :rules="{ required: true, message: '审核状态不能为空', trigger: 'blur' }">
          <el-radio-group v-model="form.apply_status">
            <el-radio :label="3">客服驳回</el-radio>
            <el-radio :label="2">客服受理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.apply_status == 3" label="审核备注或拒绝理由:" prop="remark" :rules="{ required: true, message: '退货原因不能为空', trigger: 'blur' }">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入原因"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">{{ form.id? '立即保存':'立即创建' }}</el-button>
          <el-button @click="addAttributeState=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>-->
    <!--菜单管理弹框-->
    <el-dialog
      :title="this.state == 'add'?'新建菜单':'编辑菜单'"
      :visible.sync="pathSwitch"
      :close-on-click-modal="false"
      width="560px"
      center
    >
      <el-form ref="form" :model="form" label-width="120px" label-position="left" size="small">
        <el-form-item prop="company_id" label="提款单位名称">
          <el-select
            v-model="form.company_id"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="s_company_id" label="转款单位名称">
          <el-select
            v-model="form.s_company_id"
            filterable
            clearable
            placeholder="请输入转款单位"
            :filter-method="getCompanyId"
            @visible-change="getCompanyId"
          >
            <el-option
              v-for="item in s_company_id_arr"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="money" label="提款金额" :rules="[{ required: true, message: '提款金额不能为空'},]">
          <el-input-number v-model="form.money" :precision="2" :step="0.1" :min="0"></el-input-number>
        </el-form-item>

        <el-form-item prop="type" label="提款类型" :rules="[{ required: true, message: '提款类型不能为空'},]">
          <el-select v-model="form.type" placeholder="请选择提款类型">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item,index) in typeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="remark" label="提款备注" :rules="[{ required: true, message: '请输入备注'},]">
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addPathSubmit">提交</el-button>
        <el-button type="info" @click="pathSwitch = false">取消</el-button>
      </span>
    </el-dialog>

    <!--确认提款弹框-->
    <el-dialog
      title="确认提款"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="460px"
      cente
    >
      <el-form
        ref="drawMoneyForm"
        :model="drawMoneyForm"
        label-width="120px"
        size="mini"
        class="modalContainer"
        label-position="left"
      >
        <el-form-item
          label="提款途径"
          prop="pay_path_id"
          :rules="[{ required: true, message: '提款途径不能为空'},]"
        >
          <el-select
            v-model="drawMoneyForm.pay_path_id"
            filterable
            clearable
            placeholder="请输入提款途径"
            :filter-method="getPayPathId"
            @visible-change="getPayPathId()"
          >
            <el-option
              v-for="item in pay_path_id_arr"
              :key="item.id"
              :label="item.pay_path"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDrawing">确 定</el-button>
      </div>
    </el-dialog>
    <!--确认提款弹框-->
  </div>
</template>

<script>
import ldlTablePagination from "@/components/ldlTablePagination";
import ldlControlWindow from "@/components/ldlControlWindow";
import buttonBox from "@/components/buttonBox";
//  ,,getlogList
import {
  getOrderList,
  getCompany,
  getadd,
  getedit,
  getsubmit,
  getreview,
  getconfirmTransfer,
  getdel,
  getlogList,
  signWithdrawal,
  collectionPath,
} from "@/api/FundManage/WithdrawalManage";
import { getCompanyList } from "@/api/FundManage/CollectionChannels";
export default {
  name: "ReturnRequest",
  components: {
    ldlControlWindow,
    ldlTablePagination,
    buttonBox,
  },
  data() {
    return {
      s_company_id_arr: [],
      typeList: [
        { id: 1, name: "银行" },
        { id: 2, name: "支付宝" },
        { id: 3, name: "微信" },
      ],
      // 选择单位
      options: [],
      value: [],
      list: [],
      loading: false,
      companyList: [],
      // ====
      money: 1,
      state: "add",
      addAttributeState: false,
      pathSwitch: false,
      form: {},
      applyGoodsArr: [],
      logList: [],
      orderInfoList: {},
      customerInfoList: {},
      /**必要参数*/
      selectRow: undefined, //选中行
      pagingData: undefined, //getList的传参
      bottomHeight: "30%", //底部高度
      buttonBoxState: true, //开启按钮行的状态
      tableDataInfo: {
        //表格信息
        dataListInfo: [
          // {prop:'id',label:'编号',width: 160,sortable:"custom",},
          // {prop:'company_id',label:'提款单位编号',sortable:"custom",},
          // {prop:'admin_user_id',label:'提款人编号',sortable:"custom",},
          { prop: "money", label: "提款金额", sortable: "custom" },
          {
            prop: "type",
            label: "提款类型",
            type: "tag",
            data: [
              { key: 1, name: "银行", type: "" },
              { key: 2, name: "支付宝", type: "success" },
              { key: 3, name: "微信", type: "info" },
            ],
          },
          { prop: "remark", label: "提款备注" },
          {
            prop: "status",
            label: "提款状态",
            type: "tag",
            data: [
              { key: 1, name: "已录入", type: "primary" },
              { key: 2, name: "已提交", type: "info" },
              { key: 3, name: "已审核", type: "success" },
              { key: 4, name: "已转款", type: "primary" },
              { key: 5, name: "已签收", type: "success" },
            ],
          },
          {
            prop: "create_time",
            label: "录入时间",
            type: "date",
            sortable: "custom",
            width: "140",
          },
          { prop: "transfer_time", label: "确认提款时间", sortable: "custom" },
          { prop: "companyName", label: "提款单位名称" },
          { prop: "account", label: "提款人名称" },
        ], //表格列信息
        dataList: [], //表格行信息
      },
      /**必要参数*/

      // 确认提款弹框
      dialogFormVisible: false,
      drawMoneyForm: {},
      pay_path_id_arr: [],
      // 确认提款弹框
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    //获取收款途径名称
    getPayPathId(e) {
      let data = {
        pay_path: e,
      };
      collectionPath(data).then((res) => {
        this.pay_path_id_arr = res.data;
      });
    },

    //获取所属公司名称
    getCompanyId(e) {
      let data = {
        companyName: e,
      };
      getCompanyList(data).then((res) => {
        this.s_company_id_arr = res.data;
      });
    },

    //签收提款
    signFor() {
      if (this.selectRow) {
        this.$confirm("是否确定签收此提款单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            signWithdrawal(this.selectRow.id).then(() => {
              this.$message.success("签收提款成功");
              this.getList();
            });
          })
          .catch((err) => {
            this.$message.danger(err.msg);
          });
      } else {
        this.$message.error("请选中一行");
      }
    },

    deleteentering() {
      if (this.selectRow) {
        if (this.selectRow.status == 1) {
          this.$confirm("是否确定删除此提款单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              getdel({ id: this.selectRow.id }).then((res) => {
                this.$message.success(res.msg);
                this.getList();
              });
            })
            .catch(() => {});
        } else {
          this.$message.error("订单状态只能为录入状态可以删除");
        }
      } else {
        this.$message.error("请选中一行");
      }
    },
    //确认提款--->弹框打开
    confirmOperation() {
      if (this.selectRow) {
        this.drawMoneyForm = {};
        this.dialogFormVisible = true;
        this.$nextTick(function () {
          this.$refs.drawMoneyForm.clearValidate();
        });
        this.getPayPathId();
      } else {
        this.$message.error("请选中一行");
      }
    },

    //确认提款--->提交
    confirmDrawing() {
      this.$refs.drawMoneyForm.validate((valid) => {
        if (valid) {
          let data = {
            id: this.selectRow.id,
            pay_path_id: this.drawMoneyForm.pay_path_id,
          };
          getconfirmTransfer(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.dialogFormVisible = false;
              this.selectRow = undefined;
              this.getList();
            })
            .catch(() => {});
        }
      });
    },

    //审核--->弹窗打开
    openExamine() {
      if (this.selectRow) {
        this.$confirm("是否确定确定审核此提款单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            getreview({ id: this.selectRow.id }).then((res) => {
              this.$message.success(res.msg);
              this.getList();
            });
          })
          .catch(() => {});
      } else {
        this.$message.error("请选中一行");
      }
    },
    //提交提款
    onSubmit() {
      if (this.selectRow) {
        this.$confirm("是否确定提交此提款单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            getsubmit({ id: this.selectRow.id }).then((res) => {
              this.$message.success(res.msg);
              this.getList();
            });
          })
          .catch(() => {});
      } else {
        this.$message.error("请选中一行");
      }
    },
    //搜索
    remoteMethod(query) {
      console.log(query);
      this.getCompany();
    },
    //修改
    amendentering() {
      if (this.selectRow) {
        this.form = { ...this.selectRow };
        this.pathSwitch = true;
        this.getCompany();
        this.getCompanyId(this.form.s_companyName);
      } else {
        this.$message.error("请选中一行");
      }
    },
    //录入--->弹窗打开
    addentering() {
      this.pathSwitch = true;
      this.getCompany();
    },
    //公司接口
    getCompany() {
      getCompany().then((res) => {
        // console.log(res)
        this.companyList = res.data;
      });
    },
    //保存form
    addPathSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id) {
            // 编辑
            getedit(this.form).then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.selectRow = undefined;
              this.pathSwitch = false;
              this.getList();
            });
          } else {
            //新增
            getadd(this.form).then((res) => {
              // console.log(res)
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.pathSwitch = false;
              this.getList();
            });
          }
        }
      });
    },

    //点击查看详情
    listClick(e) {
      this.applyGoodsArr = e.product;
      getlogList({ id: e.id }).then((res) => {
        // console.log(res)
        this.applyGoodsArr = res.data;
      });
    },

    /**这是按钮方法调用*/
    functionCall(name) {
      // console.log(name)
      if (name.length == 1) {
        this[name[0]]();
      } else {
        this[name[0]](name[1]);
      }
    },
    /**这是按钮方法调用*/

    getList() {
      getOrderList(this.pagingData).then((res) => {
        this.pagingData = {
          ...this.pagingData,
          page: res.data.current_page,
          limit: res.data.per_page,
          total: res.data.total,
        };
        let tableDataInfo = { ...this.tableDataInfo, dataList: res.data.data };
        this.tableDataInfo = tableDataInfo;
      });
    },
  },
};
</script>

<style scoped>
.orderInfo {
  display: inline-block;
}
.orderInfo p {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 0;
  margin-right: 15px;
  font-size: 14px;
}
</style>