import request from '@/utils/request'
// 提款管理列表
export function getOrderList(data) {
    return request({
        url: '/admin/withdrawal/getList',
        method: 'post',
        data: data
    })
}
// 获取账号所属单位
export function getCompany(data) {
    return request({
        url: '/admin/company/getCompany',
        method: 'post',
        data: data
    })
}
// 录入提款
export function getadd(data) {
    return request({
        url: '/admin/withdrawal/add',
        method: 'post',
        data: data
    })
}
// 修改提款
export function getedit(data) {
    return request({
        url: '/admin/withdrawal/edit',
        method: 'post',
        data: data
    })
}
// 提交提款
export function getsubmit(data) {
    return request({
        url: '/admin/withdrawal/submit',
        method: 'post',
        data: data
    })
}
// 审核提款
export function getreview(data) {
    return request({
        url: '/admin/withdrawal/review',
        method: 'post',
        data: data
    })
}
// 确认转款
export function getconfirmTransfer(data) {
    return request({
        url: '/admin/withdrawal/confirmTransfer',
        method: 'post',
        data: data
    })
}
// 删除提款
export function getdel(data) {
    return request({
        url: '/admin/withdrawal/del',
        method: 'post',
        data: data
    })
}
// 提款单操作日志
export function getlogList(data) {
    return request({
        url: '/admin/withdrawal/logList',
        method: 'post',
        data: data
    })
}

// 签收提款
// 参数名	必选	类型	说明
// id	是	int	提款id
export function signWithdrawal(id) {
    return request({
        url: '/admin/withdrawal/signFor',
        method: 'post',
        data: {id}
    })
}

// 模糊搜索列表(收款途径)
// 参数名	必选	类型	说明
// pay_path	否	string	收款途径名称
export function collectionPath(data) {
    return request({
        url: '/admin/pay_path/getSimpleList',
        method: 'post',
        data: data
    })
}