import request from '@/utils/request'
/** 资金账单列表
参数名	必选	类型	说明
company_id	否	int	单位，不传默认当前用户登录单位
start_time	否	date	开始时间 格式”2020-1-12”,不传默认当月1号
end_time	否	date	结束时间 不传默认当天
 */
export function get_list(data) {
    return request({
        url: '/admin/Bill/b_list',
        method: 'post',
        data: data
    })
}
/** 导出业务明细
参数名	必选	类型	说明
company_id	是	int	单位id
time	是	string	时间，格式”2020-1-4”
 */
export function exports(data) {
    return request({
        url: '/admin/Bill/b_zi_view',
        method: 'post',
        data: data
    })
}

/** 获取单位列表

 */
export function getCompanyList(data) {
    return request({
        url: '/admin/Bill/getCompany ',
        method: 'post',
        data: data
    })
}

