import request from '@/utils/request'
/** 商品属性列表
参数名	必选	类型	说明
dbname	否	string	排序字段 默认：create_time
sort	否	string	排序顺序 默认：desc
limit	否	int	条数 默认：20
page	否	int	页码 默认：1
key	否	json	高级搜索 key
 */
export function getList(data) {
    return request({
        url: '/admin/pay_path/getList',
        method: 'post',
        data: data
    })
}

/** 新增
参数名	必选	类型	说明
pay_path	是	string	收款途径名称
company_id	是	int	所属公司
qr_code	否	string	收款二维码
remake	否	string	备注信息
status	否	int	是否启用 1否 2是
pay_type	是	int	收款类型 1微信 2支付宝 3银行转账
 */
export function add(data) {
  return request({
      url: '/admin/pay_path/add',
      method: 'post',
      data: data
  })
}

/** 编辑
参数名	必选	类型	说明
id	是	string	收款途径id
pay_path	是	string	收款途径名称
company_id	是	int	所属公司
qr_code	否	string	收款二维码
remake	否	string	备注信息
status	否	int	是否启用 1否 2是
pay_type	是	int	收款类型 1微信 2支付宝 3银行转账
 */
export function edit(data) {
  return request({
      url: '/admin/pay_path/edit',
      method: 'post',
      data: data
  })
}


/** 模糊搜索列表(所属公司名称)
参数名	必选	类型	说明
companyName	否	string	公司名称
 */
export function getCompanyList(data) {
  return request({
      url: '/admin/company/getSimpleList',
      method: 'post',
      data: data
  })
}

/** 是否启用
参数名	必选	类型	说明
id	是	string	收款途径id
status	是	int	是否启用 1否 2是
 */
export function changeState(data) {
  return request({
      url: '/admin/pay_path/changeStatus',
      method: 'post',
      data: data
  })
}
