import request from '@/utils/request'
/** 退货申请列表*/
export function index(data) {
    return request({
        url: '/admin/order_refund/index',
        method: 'post',
        data: data
    })
}

/** 退换货客户信息 */
export function refundCustomer(data) {
    return request({
        url: '/admin/order_refund/refund_customer',
        method: 'post',
        data: data
    })
}

/** 原订单信息*/
export function refundOriginalOrder(data) {
    return request({
        url: '/admin/order_refund/refundOriginalOrder',
        method: 'post',
        data: data
    })
}

/** 审核 */
export function toExamine(data) {
    return request({
        url: '/admin/order_refund/refundReview',
        method: 'post',
        data: data
    })
}

/** 审核*/
export function log(data) {
    return request({
        url: '/admin/order_refund/log',
        method: 'post',
        data: data
    })
}

/** 确认退款*/
export function refund(data) {
    return request({
        url: '/admin/order_refund/refund',
        method: 'post',
        data: data
    })
}

