<template>
  <div class="pageWrap">
    <button-box :buttonBoxState.sync="buttonBoxState" @Callback="functionCall"></button-box>
    <ldl-table-pagination :selectRow.sync="selectRow" :style="{height:'calc(100% - '+ bottomHeight + (buttonBoxState?' - 35px':' - 15px')+')'}" @getList="getList" :tableDataInfo="tableDataInfo" :pagingData.sync="pagingData"></ldl-table-pagination>
  </div>
</template>

<script>
import ldlTablePagination from "@/components/ldlTablePagination";
import buttonBox from "@/components/buttonBox";
import {getList,exports} from "@/api/FundManage/OrderFunds";
export default {
  name: "OrderFunds",
  components:{
    ldlTablePagination,
    buttonBox,
  },
  data(){
    return{
      expressList: [],
      express: '',
      tableData: [],
      tableHeader: [],
      uploadLoading: false,
      uploadShow: false,
      /**必要参数*/
      selectRow:undefined, //选中行
      pagingData:undefined,//getList的传参
      bottomHeight: '0%',//底部高度
      buttonBoxState:true,//开启按钮行的状态
      tableDataInfo:{ //表格信息
        dataListInfo:[
          {prop:'water_code',label:'业务单号',},
          {prop:'water_name',label:'业务名称',},
          {prop:'water_type',label:'流水类型',},
          {prop:'water_fee',label:'流水金额',sortable:"custom"},
          {prop:'sz_type',label:'收支类型',},
          {prop:'before_money',label:'变动前余额',sortable:"custom"},
          {prop:'after_money',label:'变动后余额',sortable:"custom"},
          {prop:'create_time',label:'记账时间',type:"date",sortable:"custom"},
          {prop:'companyName',label:'单位名称',sortable:"custom"},
        ],//表格列信息
        dataList:[]//表格行信息
      },
      /**必要参数*/
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.getList()
    })
  },
  methods:{

    //导出对账差异
    getExport() {
        exports({key:this.pagingData,type:1})
            .then(res => {
              const a = document.createElement('a')
              a.setAttribute('href', `${res.data}`)
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a) // 下载后移除a标签
            })
            .catch(err => this.$message.error(err))
    },

    /**这是按钮方法调用*/
    functionCall(name) {
      // console.log(name)
      if (name.length == 1) {
        this[name[0]]();
      } else {
        this[name[0]](name[1]);
      }
    },
    /**这是按钮方法调用*/

    getList(){
      let pagingData={... this.pagingData,type:1}
      getList(pagingData).then(res=>{
        this.pagingData={...
              this.pagingData,
          page:res.data.current_page,
          limit:res.data.per_page,
          total:res.data.total,
        }
        let tableDataInfo={... this.tableDataInfo,dataList:res.data.data}
        this.tableDataInfo=tableDataInfo
      })
    }
  },

}
</script>

<style scoped>
.expressSelect {
  margin-bottom: 20px;
}
</style>
<style>
.FR_modalFoot{
  display: block !important;
  width: 100% !important;
  padding-top: 10px !important;
  text-align: center;
}
</style>